@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.factura-card {
  width: 100%;
  background-color: $btnFilterBg;
  border-radius: 8px;
  padding: 13px;

  @include xl {
    display: grid;
    grid-template-columns: 3.1fr 4.1fr 2fr 1fr;
  }
  
  &__num-pieza {
    display: grid;
    grid-template-columns: 1.1fr 3fr;

    @include xl {
      grid-template-columns: 1.3fr 1.8fr;
      align-items: center;
    }
    
    &--numero, &--pieza {
      @include flexCol(flex-start, flex-start);
      gap: 1px;
    }

    &--numero {
      &--num {
        @include primaryText(14px);
        text-decoration: underline;
        font-weight: 500;
      }
      &--fecha {
        max-width: 90px;
        font-size: 13px;

        @include xl {
          font-size: 12px;
        }

        @include custom(1400px) {
          font-size: 13px;
        }
      }
    }

    &--pieza {
      font-size: 15px;
      gap: 10px;

      @include xl {
        font-size: 14px;
      }

      @include custom(1400px) {
        font-size: 15px
      }

      &--each {
        width: 100%;
        @include flexCol(flex-start, center);
        
        @include xlMax {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;
        }

        &--nombre {
          @include capitalize();
          @include primaryText(16px);
          font-weight: 500;
  
           @include xl {
            font-size: 15px;
           }
  
           @include custom(1400px) {
            font-size: 16px;
          }
        }
      }

    }
  }
  
  &__titles {
    display: grid;
    grid-template-columns: 1.1fr 1fr 1fr 1fr;
    @include grayText (10px);
    padding: 16px 0 5px;

    @include xl {
      display: none;
    }
  }
  
  &__info-precio, &__envio-container {
    display: grid;
    grid-template-columns: 1.1fr 1fr 1fr 1fr;
    font-size: 14px;
    @include xl {
      align-items: center;
      text-align: center;
      font-size: 13px;
    }

    @include custom(1400px) {
      font-size: 14px;
    }
  }

  &__info-precio--presupuesto {
      color: $primary;
  }

  &__envio-container {
    padding-bottom: 14px;
    @include xl {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 0;
    }
    &--envio {
      @include flexCol(flex-start, flex-start);

      @include xl {
        align-items: center;
        justify-content: center;
      }
      &--num {
        text-decoration: underline;
      }
      &--proveedor {
        font-size: 13px;

        @include xl {
          font-size: 12px;
        }

        @include custom(1400px) {
          font-size: 13x;
        }
      }
    }
    &--estado {
      @include capitalize();
    }
  }

  &__descargar {
    width: 100%;
    @include flexCol(center, center);
    gap: 8px;
    color: $grayColor;
    cursor: pointer;
    
    @include xlMax {
      padding: 14px 0 0;
      border-top: 1px solid $grayColor;
    }

    &--icon {
      border: 1px solid $grayColor;
      width: 32px;
      height: 32px;
      @include flexCol(center, center);
      font-size: 21px;
      border-radius: 5px;
      position: relative;

      &:active {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
      }

      @include xl {
        width: 38px;
        height: 38px;
        font-size: 23px;
        border-radius: 12px;
      }
    }

    &--text {
      font-size: 12px;
      max-width: 70px;
      text-align: center;

      @include xl {
        font-size: 11px;
      }

      @include custom(1400px) {
        font-size: 12px;
      }
    }
  }
}