@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.detalle-carrito {
    @include flexCol(flex-start, flex-start);
    gap: 7px;

    &__header {
        display: grid;
        grid-template-columns: 5fr 1.6fr 1fr;
        width: 100%;
        @include grayText(8px);

        @include md {
            font-size: 10px;
        }
        
        @include lg {
            font-size: 12px;
            grid-template-columns: 3.5fr 1fr 1fr;
        }
        
        @include custom(1300px) {
            grid-template-columns: 5.4fr 1.6fr 1fr;
        }

        &--precio {
            justify-self: center;

            @include lg {
                justify-self: end;
            }
            
            @include custom(1300px) {
                justify-self: center;
            }
        }
    }
    
    &__pieza {
        display: grid;
        grid-template-columns: 0.7fr 4.3fr 1.6fr 1fr;
        width: 100%;
        background-color: $btnFilterBg;
        border-radius: 6px;
        align-items: center;
        min-height: 56px;

        &:hover {
            cursor: pointer;
            transform: scale(1.01);
            transition: all 100ms ease-in-out;
        }
        
        &.blocked {
            cursor: default !important;
            transform: scale(1) !important;
        }
        
        @include sm {
            height: auto;
        }
        
        @include lg {
            height: 179px;
        }
        
        @include custom(1300px) {
            max-width: 740px;
        } 
        
        &--foto {
            @include flexCol(center, center);
            padding: 5px;
            padding-right: 8px;
            
            @include lg {
                padding: 10px 12px;
            }

            @include custom(1300px) {
                padding-right: 18px;
            }
            
            span {
                width: 48px;
                height: 48px;
                overflow: hidden;
                overflow: hidden;
                background-color: $white;
                border-radius: 6px;
                @include flexCol(center, center);

                @include sm {
                    width: 50px;
                    height: 50px;
                }

                @include md {
                    width: 56px;
                    height: 56px;
                }

                @include lg {
                    width: 100px;
                    height: 100px;
                }

                @include xl {
                    width: 120px;
                    height: 120px;
                }

                @include custom(1300px) {
                    width: 130px;
                    height: 130px;
                }
            }

            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
        
        &--info {
            padding-left: 2px;
            padding-top: 10px;
            padding-bottom: 10px;

            @include lg {
                padding-top: 15px;
                padding-bottom: 15px;
            }
            
            h4 {
                @include primaryText(12px);
                @include capitalize();
                
                @include lg {
                    font-size: 17px;
                }
                
                @include xl {
                    font-size: 20px;
                    padding-bottom: 4px;
                }
            }
            
            &--subtitle {
                font-size: 11px;
                text-transform: uppercase;
                
                @include lg {
                    font-size: 15px;
                }
                
                @include xl {
                    font-size: 18px;
                }
            }
            
            &--labels {
                @include primaryText(13px);
                padding: 12px 0;
                display: flex;
                gap: 7px;
                
                span {
                    background-color: $white;
                    border-radius: 10px;
                    padding: 3px 10px;
                    
                    @include xl {
                        padding: 5px 12px;
                    }
                }
            }
            
            &--observaciones {
                font-size: 12px;
                @include capitalize();
                
                @include xl {
                    font-size: 13px;
                }
            }
        }
        
        &--precio {
            font-size: 10px;
            font-weight: 600;
            justify-self: center;
            position: relative;

            @include sm {
                font-size: 12px;
            }

            span {
                position: absolute;
                font-size: 10px;
                width: 85px;
                top: 10px;
                left: -56px;
                text-align: center;
                padding: 4px;
                color: rgb(231, 76, 76);
                border-radius: 16px;
                
                @include md {
                    color: $white;
                    background-color: rgb(231, 76, 76);
                    width: 100px;
                    font-size: 11px;
                    top: -5px;
                    left: -130px;
                    padding: 5px;
                }
                
                @include lg {
                    left: -150px;
                    padding: 10px;
                }
            }
            
            @include lg {
                font-size: 18px;
            }
            @include xl {
                font-size: 20px;
            }
        }

        &--eliminar {
            @include btnEditDelete();
            justify-self: center;
            position: relative;
            
            &:active {
                -webkit-transform: scale(0.96);
                transform: scale(0.96);
              }

            &:hover  {
                box-shadow: 2px 2px 8px $boxShadow;
            }

            &.disabled-to-delete {
                cursor: default !important;

                &:active {
                    -webkit-transform: none !important;
                    transform: none !important;
                  }
            }
        }
    }

    &__no-data {
        @include basicsNoData(110px);
        align-items: center;

        @include lg {
            height: 150px;
        }
    }

}