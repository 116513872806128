@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.modal-send-email {
  @include modalBackdrop();

    &__container {
        @include modalContainer();
        min-height: 100%;
        @include flexCol(center, center);
        gap: 5px;

        &--close {
            @include modalBtnClose();
        }

        &--title {
            @include primaryText(30px);
        }

        &--subtitle {
            font-weight: 500;
            padding-bottom: 30px;
        }

        form {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}