@import '../theme/variables';

@mixin flexCol ($align, $justify){
    display: flex;
    flex-direction: column;
    align-items: $align;
    justify-content: $justify;
}

@mixin flexRow ($align, $justify) {
    display: flex;
    flex-direction: row;
    align-items: $align;
    justify-content: $justify;
}

@mixin capitalize () {
    text-transform: lowercase;
        &::first-letter {
            text-transform: capitalize;
        }
}

@mixin activeAndDisableFilterBtn () {
    position: relative;
    
    &--active {
        display: none;
    }
    
    &--disabled {
        width: 100% !important;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        z-index: 20;
        background-color: $white;
        opacity: 0.3;
    }
}

@mixin button ($width, $height, $padding) {
    width: $width;
    height: $height;
    padding: $padding;
    color: $white;
    background-color: $primary;
    border: none;
    border-radius: 8px;
}

@mixin bgImg ($url){
    background-image: url($url);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin basicIconsMobNav () {
    border: 1px solid $primary;
    background-color: transparent;
    height: 40px;
    width: 40px;
    padding: 0.4rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.6rem;
}

@mixin basicIconsDeskNav () {
    border: 1px solid $primary;
    cursor: pointer;
    border-radius: 6px;
    color: $primary;  
}

@mixin basicInputCatalogoPiezas () {
    height: 32px;
    border: 1px solid $primary;
    border-radius: 8px;
    padding: 12px;
    font-size: 13px;
}

@mixin basicInputAuth () {
    width: 100%;
    height: 36px;
    border-radius: 8px;
    border: none;
    outline-color: $primary;
    padding: 0 0.5rem;
  
    @include lg {
        height: 45px;
    }
}

@mixin basicLabelAuth () {
    @include flexCol(flex-start, space-between);
    height: 60px;
    width: 100%;
    font-weight: 400;
    @include primaryText(13px);
    position: relative;

    @include lg {
        font-size: 16px;
        height: 75px;
        line-height: 25px;
    }
}

@mixin basicEyeAuth () {
    position: absolute;
    right: 8px;
    top: 58%;
    cursor: pointer;
    
    @include lg {
        right: 10px;
        top: 56%;
    }
}

@mixin basicErrorAuth () {
    @include flexRow(flex-start, flex-start);
    padding-top: 7px;
    gap: 3px;
    font-size: 13px;
    color: $danger;

    @include lg {
        font-size: 15px;
    } 
}
  
@mixin basicResetInput () {
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-background-clip: text;
        box-shadow: inset 0 0 20px 20px $white;
    }
}

@mixin btnEditDelete () {
    @include flexCol(center, center);
    height: 24px;
    width: 24px;
    @include grayText(14px);
    border: 1px solid $grayColor;
    border-radius: 5px;
    cursor: pointer;

    @include lg {
        width: 38px;
        height: 38px;
        border-radius: 12px;
        font-size: 18px;
    }
    
}

@mixin inputCheckbox () {
    height: 14px;
    width: 14px;    
    border-color: $primary;  
    cursor: pointer; 
    accent-color: $primary;
}

@mixin basicBusquedaAvanzada () {
    @include flexRow(center, flex-start);
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline;
    gap: 6px;
    cursor: pointer;

    @include md {
        font-size: 15px;
    }

    @include xl {
        font-size: 16px;
    }
}

@mixin basicsCardDireccion () {
    @include flexRow(center, space-between);
    width: 100%;
    border: 1px solid $primary;
    border-radius: 8px;
    padding: 12px 10px;

    div {
        padding-right: 10px;
        h3 {
            font-size: 13px;
            color: $primary;
        }
        
        h4 {
            font-size: 12px;
            padding-top: 3px;
            font-weight: 500;
        }
        
        p {
            padding-top: 5px;
            font-size: 12px;
        }
    }
}

@mixin basicsEachSectionAreaPersonal () {
    @include flexCol(flex-start, flex-start);
    width: 100%;
    @include sm {
        padding: 0 55px;
    }

    @include md {
        padding: 80px;
    }

    @include lg {
        padding-right: 26px;
    }
}

@mixin titleAreaPersonal () {
    color: $primary;
    font-size: 24px;
    font-weight: 500;
    padding: 12px 0 10px;

    @include md {
        padding: 0 0 15px;
        font-size: 40px;
    }
}

@mixin basicsInputAreaPersonal () {
    width: 100%;
    height: 40px;
    background-color: $btnFilterBg;
    border-radius: 8px;
    padding-left: 12px;

    @include md {
        height: 50px;
    }
}

@mixin returnArrowAreaPersonal () {
    color: $primary;
    font-size: 1.5rem;
    cursor: pointer;

    @include md {
        display: none;
    }
}

@mixin scrollbar ($colorBar, $colorBg, $whidth:0.3rem) {
    &::-webkit-scrollbar {
        width: $whidth; 
      }
      
    &::-webkit-scrollbar-track {
       background: $colorBg;
     }
      
    &::-webkit-scrollbar-thumb {
       background: $colorBar;
       border-radius: 1rem;
     }
}

@mixin grayText($font-size) {
    font-size: $font-size;
    color: $grayColor;
}

@mixin primaryText($font-size) {
    font-size: $font-size;
    color: $primary;
}

@mixin modalBackdrop () {
    position: fixed;
    display: block;
    left: 0;
    z-index: 10000;
    width: 100%;
    overflow-y: auto;
    height: 100%;
    top: -1200px;
    transition: 0.6s;
    
    &--open {
        top: 0;
    }

    &__overlay {
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .5);
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        display: block;
    }
    
    @include scrollbar(transparent, transparent, 0rem);
    
    @include sm {
        z-index: 200;
        padding: 140px 10%;
    }

    @include custom(1400px) {
        padding: 8% 15%;
    }
    
    @include custom(1600px) {
        padding: 8% 20%;
    }

}

@mixin modalContainer () {
    background-color: $white;
    padding: 16px 16px 30px;
    box-shadow: 3px 3px 20px $boxShadow;
    width: 100%;
    position: relative;
    
    @include sm {
        border-radius: 14px;
    }
        
    @include lg {
        padding: 25px 40px;
    }

    @include custom(1600px) {
        padding: 25px 70px 30px;
    }
}

@mixin modalBtnClose () {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 22px;
    cursor: pointer;

    @include lg {
        top: 20px;
        right: 20px;
    }
}

@mixin basicInputSearch () {
    width: 100%;
    position: relative;

    @include xl {
        width: 420px;
    }

    input {
        height: 36px;
        width: 100%;
        border: 1px solid $primary;
        border-radius: 8px;
        padding-left: 12px;
        outline-color: $primary !important;

        @include md {
            height: 40px;
            font-size: 14px;
        }

        @include xl {
            height: 45px;
            font-size: 16px;
                    }
        
        &::placeholder {
            color: $primary;
        }
    }

    span {
        position: absolute;
        right: 12px;
        top: 25%;
        color: $primary;
        cursor: pointer;
        font-size: 18px;

        @include md {
            font-size: 20px;
        }

        @include xl {
            font-size: 24px;
        }
    }
}

@mixin basicInput () {
    height: 32px;
    padding: 5px;
    border: 1px solid $primary;
    outline-color: $primary;
    border-radius: 6px;
    font-size: 13px;
    min-width: 97px;
    text-align: center;
}

@mixin basicInputDate () {
    background-color: transparent;
    &::-webkit-calendar-picker-indicator {
        position: absolute;
        margin-left: -0.7vw;
        width:5vw;
        background: transparent;
        cursor: pointer;
      }

}

@mixin basicsAddNewButton () {
    @include flexRow(center, center);
    border: none;
    background-color: $white;
    box-shadow: 3px 3px 25px $boxShadow;
    padding: 12px 18px 12px 12px;
    border-radius: 8px;
    color: $primary;
    font-weight: 500;
    height: 40px;
    margin: 11px 0;
    cursor: pointer;

    span {
         @include flexRow(center, center);
         background-color: $primaryLight;
         margin-right: 16px; 
         width: 24px;
         height: 24px;
         border-radius: 6px;
         font-size: 01.1rem;
    };
}

@mixin basicsNoData ($height) {
    @include flexCol(flex-start, center);
    background-color: $btnFilterBg;
    width: 100%;
    height: $height;
    color: $primary;
    padding: 16px;
    border-radius: 8px;
}

@mixin skeletonBasics ($height, $width, $border-radius) {
    width: $width;
    height: $height;
    border-radius: $border-radius;
}

@mixin skeletonBasicsAnimation ($bgPrimary, $bgSecondary) {
    overflow: hidden;
    background: $bgPrimary;

    &::before {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        animation: loading 1s infinite;
        background: linear-gradient(to right, transparent,$bgSecondary,transparent);
    }

    @keyframes loading {
        0%{
            transform: translateX(-100%);
        }
        100%{
            transform: translateX(100%);
        }
    }

}
