@import '../../../scss/theme/variables';
@import '../../../scss/styles/mixins';
@import '../../../scss/theme/breakpoints';

.contacto-form {
  @include flexCol(center, center);
  gap: 9px;
  width: 100%;
  padding: 0 16px;

  @include sm {
    max-width: 600px;
    padding: 0 40px;
  }

  @include md {
    padding-bottom: 80px;
  }

  &__each-input {
    width: 100%;

    input, textarea {
      width: 100%;
      height: 32px;
      border: 1px solid $primary;
      border-radius: 6px;
      padding: 12px;
      outline-color: $primary;

      @include md {
        height: 40px;
      }
      
      &::placeholder {
        color: $primary;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-background-clip: text;
        box-shadow: inset 0 0 20px 20px $white;
    }
    
    textarea {
      resize: none;
      height: 88px;

      @include md {
        height: 112px;
      }
    }

    &--error {
      width: 100%;
      font-size: 13px;
      padding: 5px 0px 0px;

      &--message {
        @include flexRow(center, flex-start);
        gap: 4px;
        color: $danger;
        
        svg {
          width: 13px;
        }
      }
    }
  }

  &__condiciones {
    @include flexRow(flex-start, flex-start);
    width: 100%;
    gap: 8px;
    color: $primary;
    padding-bottom: 28px;

    input {
      @include inputCheckbox();
      margin-top: 1px;
    }
    a {
      color: $primary;
      font-weight: 500;
      &:link, &:visited, &:active {
        text-decoration: none;
    }
    }
  }

  &__btn {
    button {
      @include button(120px, 32px, auto);
      cursor: pointer;
      position: relative;

      @include md {
        height: 40px;
        width: 180px;
        font-size: 17px;
      }

      &:disabled {
        opacity: 0.8;
        cursor: default;
      }

    }
  }
}