@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.filtro-button{
    @include flexRow(center, space-between);
    height: 36px;
    width: 100%;
    background-color: $white;
    border-radius: 8px;
    position: relative;
    border: 1px solid $primary;
    font-size: 13px;
    font-weight: 500;

    @include md {
        height: 40px;
    }
    
    @include lg {
        width: 350px;
    }
    
    @include xl {
        width: 420px;
        height: 45px;
        font-size: 16px
    }

    &__option1, &__option2 {
        @include flexRow(center, center);
        height: 100%;
        width: 100%;
        z-index: 1;
        flex-grow: 1;
        cursor: pointer;
        color: $white;
    }

    &__option1--primary-text, &__option2--primary-text {
        color: $primary;
    }

    &__board {
        height: 36px;
        width: 50%;
        position: absolute;
        background-color: $primary;
        z-index: 0;
        transition: transform 0.3s;

        @include md {
            height: 40px;
        }
        
        @include xl {
            height: 45px;
        }
    }

    &__left {
        border-radius: 8px;
    }

    &__right {
        transform: translate(100% , 36px*-0.01);
        border-radius: 8px;
        background-color: $primary;
        
        @include md {
            transform: translate(100% , 40px*-0.01);
        }

        @include xl {
            transform: translate(100% , 45px*-0.01);
        }
      }

}