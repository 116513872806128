@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.pieza-detalle {
    padding-top: 85px;
    width: 100%;
    @include flexCol(flex-start, flex-start);

    @include sm {
        padding: 100px 39px 0;
    }

    @include md {
        padding: 100px 100px 0;
    }

    @include lg {
        padding: 130px 16px 16px;
        
        &__container {
            @include flexRow(center, flex-start);
            height: 622px;
            width: 100%;
        }
    }

    @include custom(1400px) {
        width: 1400px;
        margin: auto;
    }

    &__back {
        margin-left: 16px;
        margin-bottom: 20px;
        @include primaryText(25px);
        cursor: pointer; 
    }

    &__whatsapp {
        padding-right: 56px;
    }

    &__container {
        width: 100%;
    }

}

