@import '../../../scss/styles/mixins';
@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';

.navcomercial {
  &__logo {
    @include flexCol(flex-start, flex-start);
    gap: 8px;
    margin-left: 1rem;
    
    @include md {
      margin-left: 55px;
    }

    img {
      height: 16px;
      width: 123px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
    }
  }
}