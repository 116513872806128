@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.area-personal {
    padding: 100px 22px 16px;

    @include md {
        @include flexRow(flex-start, flex-start);
        width: 100%;
    }

    @include lg {
        padding-top: 130px;
    }

    &__hidden {
        width: 100%;
        @include mdMax {
            display: none;
        }
        
        &.width-100 {
            width: auto;
        }
    }
    
    &__show {
        width: 100%;
    }

    &__header {
        color: $primary;

        p {
            font-size: 14px;
            font-weight: 300;
            padding-top: 4px;
        }

        @include md {
            display: none;
        }
    }

    &__sections {
        width: 100%;
        @include flexCol(center, center);
        flex-wrap: wrap;
        gap: 16px;
        padding: 40px 0;

        @include custom(500px) {
            padding: 40px 90px;
        }

        @include custom(600px) {
            flex-direction: row;
            padding: 40px 20px;
        }

        @include md {
            flex-direction: column;
            padding: 80px 16px 10px;
            width: 240px;
            gap: 10px;
        }

        div {
            @include flexCol(center, center);
            height: 96px;
            width: 100%;
            border-radius: 12px;
            box-shadow: 3px 3px 25px $boxShadow;
            color: $primary;
            gap: 8px;
            cursor: pointer;
            
            @include custom(600px) {
                width: 156px;
            }
            
            @include md {
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                height: 54px;
                box-shadow: none;
                padding-left: 5px;
            }

            span {
                @include flexCol(center, center);
                width: 42px;
                height: 42px;
                background-color: $primaryLight;
                border-radius: 12px;
                font-size: 1.2rem;

                @include md {
                    width: 38px;
                    height: 38px;
                    font-size: 1rem;
                }
                
                .pedidos-logo {
                    width: 20px;
                    height: 20px;
                }
            }

            .facturas-span {
                font-size: 20px;
            }

            .incidencias-span, .location-span {
                font-size: 24px;
            }

            p {
                font-size: 14px;
                font-weight: 500;

                @include md {
                    font-size: 15px;
                    font-weight: 400;
                }
            }
        }

        &--path-active {
            @include md {
                border: 1px solid $primary;
                
                p {
                    font-weight: 700 !important;
                }
            }
        }

        &--fav-icon {
            svg {
              font-size: 24px;
            }
        }
    }

    &__logout {
        @include flexRow(center, flex-start);
        gap: 10px;
        padding: 22px 0 47px;
        color: $primary;
        cursor: pointer;

        @include md {
            padding-left: 18px;
            gap: 8px;
        }

        span {
            @include flexCol(center, center);

            @include md {
                width: 42px;
                height: 42px;    
            }
            
            img {
                width: 18px;
                height: 18px;
            }
        }

        p {
            font-size: 15px;

            @include md {
                font-size: 16px;
            }
        }
    }
}