@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.incidencias {
    @include basicsEachSectionAreaPersonal();

    @include md {
        padding-left: 80px;
        padding-right: 55px;
    }
    
    @include xl {
        max-width: 1200px;
        padding-left: 40px;
        padding-right: 24px;
    }
    
    @include custom(1300px) {
        padding-left: 80px;
        padding-right: 55px;

    }
    
    &__return {
        @include returnArrowAreaPersonal();
    }

    h2 {
        @include titleAreaPersonal();
        padding-bottom: 3px;

        @include xl {
            padding-bottom: 9px;
        }
    }

    &__filters {
        width: 100%;
        padding-top: 16px;

        @include lg {
            @include flexCol(center, center);
            gap: 40px;
            padding-top: 20px;
            padding-bottom: 30px;
        }

        @include xl {
            padding-bottom: 40px;
        }

        &--container {
            @include flexCol(flex-end, flex-end);
            width: 100%;
            gap: 9px;
            padding: 16px 0;

            @include xl {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            &--search {
                @include basicInputSearch();
            }

            &--btn {
                @include basicsAddNewButton();
                padding: 7px;

                @include xl {
                    height: 45px;
                    font-size: 16px;
                    padding: 9px;
                }
                
                span {
                    margin-right: 10px;
                    
                    @include xl {
                        height: 32px;
                        width: 32px;
                        font-size: 22px;
                        margin-right: 12px;
                    }
                }
            }
        }
    }
}