@import '../../../scss/theme/variables';
@import '../../../scss/styles/mixins';
@import '../../../scss/theme/breakpoints';

.confirm {
    width: 360px;
    height: auto;
    background: #fff;
    transition: .6s;
    z-index: 100;
    position: absolute;
    top: -280px;
    left: calc(50% - 180px);
    border-radius: 8px;
  }
  
  .confirm.show {
    top: calc(50% - 140px);
    padding-right: 0 !important;
  }

  .confirm.text2 {

    @include lgMax {
      top: calc(50% - 200px);
    }

    @include md {
      padding: 18px 0px !important;
    }

    @include lg {
      padding: 30px 0px !important;
    }

    @include custom(1400px) {
      padding: 22px 0px !important;
    }
  }
  
  .confirm-content {
    @include flexCol(center, center);
    gap: 1px;
    height: 70%;
    padding: 32px 20px 20px;

    &__icon {
      color: $primary;
      font-size: 55px;
    }

    &__text {
      font-size: 27px !important;
      color: $primary;
      text-align: center;
    }
  }

  .confirm.ok-message {
    @include md {
      width: 500px;
      left: calc(50% - 250px);
    }
    
    @include lg {
      width: 700px;
      left: calc(50% - 350px);
    }

    @include custom(1400px) {
      width: 1000px;
      left: calc(50% - 500px);
    }
  }
  
  .confirm-btns {
    @include flexRow(center, center);
    gap: 20px;
    padding: 5px 0 25px;

    &__confirm {
      width: 92px;
      height: 33px;
      color: $primary;
      background-color: $primaryLight;
      position: relative;
      
      &.confirm-loading {
        cursor: default;
        &:hover {
          background-color: $primaryLight !important;
        }
      }
    }
    
    &__cancel {
      width: 92px;
      height: 33px;
      color: $primary;
      background-color: $btnFilterBg;
    }
  }
  
  .confirm-btns button {
    font-weight: 500;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    border: none;
  }
  
  .confirm-btns button:hover {
    background-color: $primary;
    color: $white;
    font-weight: 400;
  }
  
  .overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  
  .confirm.show + .overlay {
    display: block;
  }
  
  @media screen and (max-width: 450px) {
    .confirm {
      width: 300px;
      left: calc(50% - 150px);
    }
  }

  .ok-message-content {
    @include flexCol(center, center);
    gap: 16px;
    min-height: 242px;
    padding: 20px;

    @include lg {
      gap: 22px;
      height: 264px;
      max-width: 600px;
      margin: auto;
    }

    &__icon {
      @include flexCol(center, center);
      width: 32px;
      height: 32px;
      background-color: $primaryLight;
      border: 2px solid $primary;
      border-radius: 50%;
      @include primaryText(14px);

      @include lg {
        width: 47px;
        height: 47px;
        font-size: 22px;
      }
    }

    &__text {
      @include primaryText(16px);
      font-weight: 700;
      text-align: center;

      @include lg {
        font-size: 22px;
      }
    }

    &__text2 {
      @include flexCol(center, center);
      gap: 12px;
      text-align: center;
      font-size: 20px;
      padding: 0 12px 12px;

      @include md {
        padding: 0 60px 12px;
      }
  
      @include lg {
        padding: 0px 30px 16px;
      }
  
      @include custom(1400px) {
        padding: 0px 40px 16px !important;
      }
      
    }
  }