@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.pieza-card {
    @include flexRow(center, space-between);
    width: 100%;
    min-height: 109px;
    background-color: $btnFilterBg;
    padding: 6px;
    border-radius: 6px;
    position: relative;

    &-hover-active {
        &:hover {
            cursor: pointer;
            transform: scale(1.01);
            transition: all 100ms ease-in-out;
        }
    }

    @include lg {
        min-height: 250px;
        padding: 10px;
        border-radius: 10px;
    }

    @include custom(1300px) {
        min-height: 312px;
        max-width: 860px;
    }

    &__img {
        @include flexCol(center, center);
        height: 160px;
        width: 150px;
        border-radius: 10px;
        background-color: $white;
        overflow: hidden;

        @include sm {
            height: 180px;
            width: 170px;
        }

        @include lg {
            height: 230px;
            width: 250px;
        }
        
        @include custom(1300px) {
            width: 350px;
            height: 290px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    &__info {
        @include flexCol(flex-start, flex-start);
        width: 100%;
        padding-left: 16px;
        padding-top: 17px;
        gap: 2px;
        @include lgMax {
            padding-bottom: 3px;
            padding-top: 30px;
        }

        @include lg {
            min-height: 230px;
            padding-top: 23px;
            padding-left: 20px;
            justify-content: space-between;
        }

        @include custom(1300px) {
            min-height: 290px;
            padding-top: 27px;
        }

        &--id-ref {
            padding: 2px 0 5px;
            span {
                font-weight: 600
            }

            @include lg {
                padding: 0 0 10px;
                font-size: 20px !important;
            }

            &-prov {
                margin-top: -2px;
                padding: 0px 0 10px;

                @include lg {
                    margin-top: -8px;
                }
            }
        }

        &--nombre {
            @include primaryText(15px);
            padding-bottom: 2px;
            @include capitalize();
            max-width: 160px;

            @include sm {
                max-width: 70%;
            }

            @include lg {
                font-size: 20px;
            }

            @include custom(1300px) {
                font-size: 24px;
                padding-bottom: 3px;
            }
        }

        &--modelo {
            font-size: 13px;
            text-transform: uppercase;

            @include lg {
                font-size: 17px;
            }

            @include custom(1300px) {
                font-size: 22px;
            }
        }

        &--containerdesk {
            &--labels {
                @include flexRow(center, flex-start);
                gap: 8px;
                @include primaryText(11px);
                padding: 10px 0;
                
                @include custom(1300px) {
                    font-size: 16px; 
                    padding: 17px 0 18px;
                }

                span {
                    background-color: $white;
                    padding: 4px 12px;
                    border-radius: 10px;
                    min-width: 82px;
                    
                    @include custom(1300px) {
                        padding: 5px 15px;
                        min-width: 110px;
                    }
                }
            }

            &--observaciones {
                font-size: 13px;
                font-weight: 300;
                padding-right: 20px;
                @include capitalize();

                @include lgMax {
                    font-size: 12px;
                }
            }
        }

        &--casco {
            font-size: 10px;
            @include flexRow(flex-start, flex-start);
            gap: 2px;
            padding: 5px 5px 5px 0;
            color: $grayColor;
            font-style: italic;
            
            span {
                padding-top: 1px;
            }

            @include lg {
                padding-top: 10px;
                font-size: 11px;
            }
        }

        &--precio {
            @include primaryText(18px);
            padding-top: 10px;

            @include lg {
                @include flexCol(flex-start, center);
                font-size: 20px;
                padding-top: 0;
                line-height: 20px;
            }

            &--iva {
                color: $fontPrimary;
                font-size: 11px;
                font-weight: 300;
                max-width: 300px;

                @include custom(1300px) {
                    font-size: 12px;
                }
            }

            &--consultar {
                font-size: 12px;
                font-weight: 400;
                @include lgMax {
                    display: none;
                }
            }

            &--old {
                text-decoration: line-through;

                @include lg {
                    font-size: 18px;
                }
            }
        }
    }

    &__disponible {
        position: absolute;
        top: 4px;
        right: 4px;
        background-color: $primaryLight;
        border-radius: 3px;
        padding: 1px 8px;
        @include primaryText(9px);

        @include lg {
            font-size: 11px;
            padding: 3px 16px;
            top: 6px;
            right: 6px;
        }

        @include custom(1300px) {
            font-size: 14px;
            padding: 4px 14px;
            border-radius: 6px;
            top: 8px;
            right: 8px;
        }
    }

    &__favorites {
        position: absolute;
        top: 24px;
        right: 4px;
        font-size: 9px;
        
        span {
            border-radius: 3px;
            padding: 2px 8px;

            @include lg {
                padding: 3px 16px;
            }

            @include custom(1300px) {
                padding: 4px 14px;
                border-radius: 6px;
            }
        }

        &:active {
            -webkit-transform: scale(0.96);
            transform: scale(0.96);
          }

        &--disabled {
            opacity: 0.7;
            cursor: default !important;
            top: 27px;

            @include lg {
                top: 35px !important;
            }

            @include custom(1300px) {
                top: 44px !important;
            }

            &:active {
                -webkit-transform: none !important;
                transform: none !important;
            }
        }
        
        @include lg {
            top: 34px;
            right: 6px;
            font-size: 11px;
        }
        
        @include custom(1300px) {
            top: 42px;
            right: 8px;
            font-size: 14px;
        }
    }

    &__en-carrito {
        border-radius: 3px;
        padding: 2px 8px;
        color: $white;
        background-color: $primary;
        text-transform: uppercase;
        font-size: 10px;
        position: absolute;
        right: 6px;
        top: 4px;
        z-index: 1;

        @include lg {
            font-size: 13px;
            top: 5px;
            right: 6px;
            padding: 3px 16px;
        }

        @include custom(1300px) {
            top: 7px;
            right: 9px;
            font-size: 15px;
            padding: 5px 14px;
        }
    }

    &__cart {
        position: absolute;
        bottom: 7px;
        right: 7px;
        border: 3px solid $white;
        border-radius: 8px;
        width: 36px;
        height: 36px;
        overflow: hidden;
        color: $primary;
        cursor: pointer;

        &:hover {
            box-shadow: 2px 2px 10px $boxShadow;
        }
        
        &:active {
            -webkit-transform: scale(0.96);
            transform: scale(0.96);
            
            @include lg {
                -webkit-transform: scale(0.98);
                transform: scale(0.98);
            }
        }
        
        &.disabled-to-add {
            opacity: 0.7;
            cursor: default;

            &:active {
                -webkit-transform: none !important;
                transform: none;
            }

            &:hover {
                box-shadow: none !important;
            }
        }

        @include lg {
            width: 150px;
            height: 43px;
            border: none;
            background-color: $white;
            @include flexRow(center, flex-start);
            padding: 0 5px;
            gap: 10px
        }

        @include custom(1300px) {
            height: 49px;
            width: 226px;
            padding-left: 8px;
            gap: 22px
        }
        
        &--icon {
            @include flexRow(center, center);
            background-color: $primaryLight;
            width: 100%;
            height: 100%;
            font-size: 1.5rem;
            position: relative;

            @include lg {
                width: 32px;
                height: 32px;
                border-radius: 6px;
            }
        }

        &--text {
            display: none;

            @include lg {
                display: flex;
                font-size: 12px;
                font-weight: 500;
            }

            @include custom(1300px) {
                font-size: 18px;
            }
        }
    }

    &__no-data-btns {
        @include flexRow(flex-end, flex-end);
        position: absolute;
        bottom: 12px;
        right: 16px;
        gap: 6px;
        
        @include lg {
            gap: 16px;
        }

        &--whats {
            @include primaryText(23px);

            @include lg {
                font-size: 32px
            }
          }
          
        &--email {
            @include primaryText(24px);

            @include lg {
                font-size: 34px;
            }
        }
          
        &--whats, &--email {
            background-color: white;
            cursor: pointer;
            height: 34px;
            width: 34px;
            box-shadow: 0px 0px 10px $boxShadow;
            border-radius:6px;
            @include flexCol(center, center);

            @include lg {
                height: 54px;
                width: 54px;
            }
        }
    }
  
}