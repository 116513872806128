@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.form-dir-envio{
    @include flexCol(flex-start, flex-start);
    gap: 10px;
    padding-top: 20px;
    width: 100%;
    position: relative;

    @include lg {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    h4 {
        @include primaryText(14px);
        padding-bottom: 10px;
        width: 100%;
    }

    &__div {
        width: 100%; 

        @include lg {
            width: 48.6%;
        }
    }

    &__div-small {
       width: 100%; 

        @include lg {
            width: 35%; 
        }
    }

    &__div-large {
        width: 100%; 

        @include lg {
            width: 62%;
        }
    }

    &__div-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%; 
        flex-wrap: wrap;
        gap: 10px;

        @include lg {
            flex-wrap: nowrap;
        }
    }

    &__alert {
        width: 100%;
        position: relative;
        padding: 12px 20px;
        margin: 10px 0;
        border: 1px solid transparent;
        border-radius: 8px;
        display: flex;
        align-items: center;
        font-size: 0.813rem;

        &-div {
            display: flex;
            align-items: center;

            div { margin-right: 10px; }
        }
        
        &.info {
            color: #004085 !important;
            background-color: #cce5ff;
            border-color: #b8daff;
        }

        &.warning {
            color: #856404 !important;
            background-color: #fff3cd;
            border-color: #ffeeba;
        }
    }

    &__direccion {
        @include lg {
            width: 100% !important;
        }
    }
    
    span {
        @include flexRow(flex-start, flex-start);
        padding-top: 7px;
        gap: 3px;
        font-size: 13px;
        color: $danger;

        @include lg {
            font-size: 15px;
        }
    }

    &__label {
        @include flexCol(flex-start, flex-start);
        gap: 5px;
        width: 100%;
        font-size: 13px;

        input, &--select {
            width: 100%;
            background-color: $btnFilterBg;
            border: none;
            border-radius: 8px;
            height: 40px;
            outline-color: $primary;
            padding: 0 16px;
            font-size: 13px;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-background-clip: text;
            box-shadow: inset 0 0 20px 20px $btnFilterBg;
        }
        
        &--select {
            appearance: none;
            &::-ms-expand {
                display: none;
            }
        }
    
    }

    &__poblacion {
        width: 100%;

        @include lg {
            width: 48.6%;
        }
    }

    &__button {
        width: 100% !important;
        @include flexRow(flex-end, flex-end);
        
        &--btn {
            border: 1px solid $primary;
            align-self: flex-end;
            justify-self: flex-end;
            height: 36px;
            border-radius: 6px;
            background-color: $white;
            color: $primary;
            font-size: 13px;
            cursor: pointer;
            margin-top: 12px;
            padding: 0 48px;
            font-weight: 500;
            width: 160px;
            position: relative;
            
            &:hover {
                background-color: $primary;
                color: $white;
            }

            &:disabled {
                opacity: 0.8;
                cursor: default;
                background-color: $white !important;
            }

            &:active {
                -webkit-transform: scale(0.98);
                transform: scale(0.98);
            }
        }

        }

    }

    .skeleton-input {
        width: 100%;
        height: 40px;
        background-size: 200% 100%;
        border-radius: 8px;
        background: $btnFilterBg;
        overflow: hidden;
        &__span {
            width: 100%;
            height: 40px;
            background: linear-gradient(90deg, $btnFilterBg 25%, $btnFotos 50%, $btnFilterBg 75%);
            animation: loading 2s infinite ease-in-out;
        }
    }

    @keyframes loading {
        0% {
            background-position: 200% 0;
        }
        100% {
            background-position: -200% 0;
        }
    }