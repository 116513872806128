@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.whatsapp-section {
    @include flexRow(center, space-between);
    width: 100%;
    padding: 17px 17px 35px;
    position: fixed;
    bottom: 0;
    z-index: 3000;

    @include custom(400px) {
        justify-content: flex-end !important;
        gap: 20px;
    }

    @include md {
        width: auto;
        right: 0
    }

    &__message {
        @include flexRow(center, space-between);
        width: 75%;
        min-height: 67px;
        position: relative;
        border: 1px solid $primary;
        border-radius: 10px;
        box-shadow: 2px 2px 20px $boxShadow;
        color: $primary;
        padding: 16px 18px;
        background-color: $white;

        @include custom(400px) {
            width: 260px;
        }

        p {
            font-size: 13px;
            width: 170px;
            line-height: 18.2px;
        }
        
        img {
            width: 36px;
            height: 16px;
        }
        
    }
    
}