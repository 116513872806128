@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.facturas-container-desk {
  padding: 14px 0 40px;
  width: 100%;

  @include md {
    padding-bottom: 0;
  }

  &__titles {
    @include grayText(10px);
    display: grid;
    grid-template-columns: 1.1fr 3fr;
    padding: 0 13px 10px;
    text-align: center;

    @include xl {
      grid-template-columns: 1.3fr 1.8fr 1.1fr 1fr 1fr 1.1fr 1fr 1fr 1fr;
      font-size: 11px;
    }

    &--left-align {
      text-align: start;
    }
  }
  
  &__container-cards {
    @include flexCol(flex-start, flex-start);
    gap: 20px;
    @include lg {
      padding-bottom: 26px;
    }

    &--nodata {
      @include basicsNoData(90px);
    }
  }
}