@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.facturas-container-mob {
  padding: 14px 0 40px;
  width: 100%;

  @include md {
    padding-bottom: 0;
  }

  &__titles {
    @include grayText(10px);
    display: grid;
    grid-template-columns: 1.1fr 3fr;
    padding: 0 13px 10px;
  }

  &__container-cards {
    @include flexCol(flex-start, flex-start);
    gap: 20px;
    
    &--nodata {
      @include basicsNoData(160px);
      align-items: center;
    }
  }

}