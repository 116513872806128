@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.incidencia-card {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1.2fr;
    background-color: $btnFilterBg;
    border-radius: 8px;
    padding: 13px;
    margin-bottom: 20px;

    @include lg {
        grid-template-columns: 1fr 1.1fr 1fr 1fr 1fr 1fr;
        margin-bottom: 12px;
    }
    
    @include xl {
        grid-template-columns: 1.1fr 1.4fr 1.1fr 1.1fr 1.1fr 1.1fr;
    }
    
    &__each-container {
        @include flexCol(flex-start, flex-start);
        font-size: 13px;
        
        @include lg {
            font-size: 11px;
        }
        
        @include xl {
            font-size: 14px;
        }

        @include lgMax {
            padding-bottom: 16px;
            &.doble-fr-grid {    
                grid-column: 2/4;
            }
    
            &.doble-padding-bottom {
                padding-bottom: 22px;
            }
        }

        @include lg {
            justify-content: center;

            &.align-text-center {
                @include flexCol(center, center);
            }
        }

        &--title {
            @include grayText(10px);
            padding-bottom: 4px;

            @include lg {
                display: none;
            }
        }

        &--numero {
            font-weight: 500;
            color: $primary;
            text-decoration: underline;

            @include xl {
                font-size: 16px;
            }
        }

        
        &--fecha {
            font-size: 12px;
            padding-top: 3px;

            @include lg {
                font-size: 10px;
            }

            @include xl {
                font-size: 13px;
            }
        }
        
        &--nombre {
            @include primaryText(15px);
            font-weight: 500;
            @include capitalize();

            @include lg {
                font-size: 13px;
            }

            @include xl {
                font-size: 16px;
            }
        }

        &--tipo {
            @include lg {
                max-width: 110px;
                text-align: center;
            }
        }

        &--modelo, &--piezaId {
            max-width: 110px;
            font-weight: 300;

            @include xl {
                font-size: 15px;
                max-width: 130px;
            }

        }

        &--factura {
            color: $primary;
        }

        &--estado {
            font-weight: 600;

            @include lg {
                max-width: 60px;
                text-align: center;
            }
        }
    }

    &__container-ver {
        @include flexCol(center, center);
        
        @include lgMax {
            width: 100%;
            grid-column: 1/4;
            border-top: 1px solid $grayColor;
            padding: 16px 0 3px;
        }

        &--icon {
            border: 1px solid $grayColor;
            height: 32px;
            width: 32px;
            @include flexCol(center, center);
            border-radius: 5px;
            cursor: pointer;

            &:active {
                -webkit-transform: scale(0.98);
                transform: scale(0.98);
            }

            @include xl {
                height: 38px;
                width: 38px;
                border-radius: 12px;
            }

            img {
                width: 14px;

                @include xl {
                    width: 16px;
                }
            }
        }

        &--text {
            @include grayText(11px);
            width: 60px;
            text-align: center;
            padding-top: 6px;
            line-height: 11px;
            cursor: pointer;

            @include lg {
                font-size: 10px;
            }

            @include xl {
                font-size: 12px;
            }
        }
    }
}