@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.skeleton-incidencia-card {
  height: 222px;

  @include lg {
    height: 86px;
  }

  @include xl {
    height: 92px;
  }
  
  &__numero {
    @include skeletonBasics(13px, 75px, 3px);
    margin-bottom: 5px;

    @include lg {
      width: 58px;
      height: 10px;
    }

    @include xl {
      width: 83px;
      height: 16px;
    }
  }
  
  &__fecha {
    @include skeletonBasics(11px, 80px, 3px);

    @include lg {
      width: 60px;
      height: 9px;
    }

    @include xl {
      width: 75px;
      height: 13px;
    }
  }
  
  &__nombre {
    @include skeletonBasics(13px, 200px, 3px);
    margin-bottom: 5px;

    @include lg {
      width: 85px;
      height: 10px;
    }

    @include xl {
      width: 140px;
      height: 16px;
    }
  }

  &__modelo {
    @include skeletonBasics(11px, 80px, 3px);

    @include lg {
      width: 65px;
      height: 9px;
    }

    @include xl {
      width: 92px;
      height: 13px;
    }
  }
  
  &__tipo {
    @include skeletonBasics(12px, 75px, 3px);

    @include lg {
      width: 60px;
      height: 10px;
    }

    @include xl {
      width: 75px;
      height: 13px;
    }
  }

  &__factura {
    @include skeletonBasics(12px, 70px, 3px);

    @include lg {
      width: 65px;
      height: 10px;
    }

    @include xl {
      width: 90px;
      height: 13px;
    }
  }

  &__estado {
    @include skeletonBasics(12px, 110px, 3px);

    @include lg {
      width: 70px;
      height: 10px;
    }

    @include xl {
      width: 100px;
      height: 16px;
    }
  }
  
  &__title {
    @include skeletonBasics(10px, 50px, 3px);
    margin-bottom: 7px;

    @include lg {
      display: none;
    }
  }

  &__container-ver {
    border-color: $btnFotos;
  }

  &__icon {
    border-color: $btnFotos;
  }

  &__text1 {
    @include skeletonBasics(8px, 30px, 3px);
    margin-top: 8px;
    margin-bottom: 3px;

    @include lg {
      height: 6px;
    }

    @include xl {
      height: 8px;
    }
  }

  &__text2 {
    @include skeletonBasics(8px, 50px, 3px);

    @include lg {
      height: 6px;
    }

    @include xl {
      width: 58px;
      height: 8px;
    }
  }
  
}

.incidencia-card-loading-animation {
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}