@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.modal-ver-incidencia {
    @include modalBackdrop();

    &__container {
        @include modalContainer();
        min-height: 100%;
        padding-bottom: 60px;

        @include lg {
            padding-bottom: 60px;
        }

        &--close {
            @include modalBtnClose();
        }

        &--title {
            padding: 35px 0 2px !important;
        }

        &--numIncidencia {
            font-size: 19px;
            padding-bottom: 3px;
        }

        &--tipo {
            font-weight: 300;
            padding-bottom: 35px;
        }

        &--titles {
            @include grayText(10px);
            display: grid;

            @include lg {
                font-size: 11px;
            }
            
            &.titles-factura-pieza {
                grid-template-columns: 1fr 1.7fr;
                padding: 6px 14px;
            }
            
            &.titles-fecha-estado {
                grid-template-columns: 1.4fr 1.3fr;
                padding: 30px 0 10px;
                border-bottom: 1px solid $grayColor;
                
                @include md {
                    grid-template-columns: 0.5fr 0.6fr 1.4fr;
                }

                @include lg {
                    grid-template-columns: 0.45fr 0.55fr 1.7fr;
                }
            }

            &--detalle {
                @include mdMax {
                    display: none;
                }
            }
        }
        
        &--card {
            background-color: $btnFilterBg;
            border-radius: 8px;
            padding: 12px 14px;
            
            &--info {
                display: grid;
                grid-template-columns: 1fr 1.7fr;

                &--factura {
                    @include primaryText(16px);
                }
                
                &--pieza {
                    @include flexCol(flex-start, flex-start);
                }
            }
            
            &--row-estado {
                display: grid;
                grid-template-columns: 1.4fr 1.3fr;
                padding: 10px 0;
                border-bottom: 1px solid $grayColor;
                font-size: 14px;
                
                @include md {
                    grid-template-columns: 0.5fr 0.6fr 1.4fr;

                    &--fecha {
                        max-width: 100px;
                    }
                }
                
                @include lg {
                    grid-template-columns: 0.45fr 0.55fr 1.7fr;
                }

                span {
                    @include md {
                        align-self: center;
                    }
                }

                &--primary-color {
                    color: $primary;
                    font-weight: 600;
                }
                
                &--black-color {
                    font-weight: 600;
                }

                &--comentario {
                    @include mdMax {
                       grid-column: 1/3;
                    }
                    @include flexCol(flex-start, flex-start);

                   &--title {
                    @include grayText(10px);
                    padding: 16px 0 6px;

                    @include md {
                        display: none;
                    }
                   }
                }
            }

            &--comentarios {
                @include flexCol(flex-start, flex-start);
                padding: 16px 0 26px;
                
                &--title {
                    padding-bottom: 6px;
                    font-weight: 600;
                    font-size: 14px;
                }
                
                &--text {
                    font-size: 14px;

                    &--nodata {
                        font-size: 14px;
                        font-style: italic;
                    }
                }
            }

            &--prueba {
                padding: 36px 0 20px;

                &--title {
                    font-weight: 600;
                    font-size: 14px;
                }

                &--container {
                    @include flexRow(center, center);
                    flex-wrap: wrap;
                    gap: 16px;
                    width: 100%;
                    padding: 30px 0 0;
                    
                    &--card {
                        padding: 16px;
                        @include flexCol(center, flex-start);
                        gap: 6px;
                        border-radius: 6px;
                        width: 47%;
                        height: 170px;
                        position: relative;
                        box-shadow: 3px 3px 25px $boxShadow;

                        @include md {
                            width: 28%;
                        }

                        &--img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            border-radius: 6px;
                        }  

                        &--icon {
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                            background-color: $white;
                            color: $primary;
                            width: 30px;
                            height: 30px;
                            @include flexCol(center, center);
                            border-radius: 6px;
                            cursor: pointer;
                            box-shadow: 3px 3px 25px $boxShadow;
                            font-size: 24px;
                        }

                        &--noimg {
                            width: 100%;
                            height: 100%;
                            @include flexCol(center, center);

                            &--icon {
                                font-size: 60px;
                                color: $primary;
                            }
                            
                        }
                    }

                    &--nodata {
                        width: 100%;
                        margin-top: -24px;
                        height: 70px;
                        font-size: 14px;
                        font-style: italic;
                    }
                }
            }

        }
    }
}