@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.facturas {
  @include basicsEachSectionAreaPersonal();

  @include md {
    padding-left: 80px;
    padding-right: 55px;
  }

  @include xl {
    max-width: 1200px;
    padding-left: 40px;
    padding-right: 24px;
  }

  &.mensajes {
    @include custom(1300px) {
      padding-left: 80px;
      padding-right: 55px;
    }
  }

  @include custom(1400px) {
    padding-left: 80px;
    padding-right: 55px;
  }

  &__return {
    @include returnArrowAreaPersonal();
  }

  h2 {
    @include titleAreaPersonal();
    padding-bottom: 3px;

    @include xl {
        padding-bottom: 9px;
    }
  }

  &__filters {
    width: 100%;
    padding-top: 16px;
    @include flexCol(center, center);
    gap: 20px;

    @include xl {
      padding: 20px 0 40px;
      gap: 40px;
    }
    
    &--container {
      width: 100%;
      gap: 20px;
      display: flex;
      flex-direction: column;

      @include xl {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 16px 0 0;
      }

      &--search {
        @include basicInputSearch();

        @include xl {
          @include flexRow(flex-start, space-between);
          width: 80%;
        }
        
        @include custom(1400px) {
          width: 715px;
        }
        
      }
    }
 
}
}