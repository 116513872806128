@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.filtros-section {
    width: 270px;
    position: fixed;
    background-color: $white;
    padding-bottom: 40px;
    min-height: 450px;

    @include mdMax {
        height: 100vh;
        width: 100%;
        overflow-y: auto;
        z-index: 100000;
        left: 0;
        top: 0;
        transition: .6s;
    }

    @include md {
        width: 330px;
        position: -webkit-sticky;
        position: sticky;
        top: 155px;

        &.from-lleida {
            top: 170px;
        }

    }
    
    @include lg {
        top: 170px;

        &.from-lleida {
            top: 188px;
        }
    }

    @include xl {
        width: 420px;
    }
    
    &--close {
        @include mdMax {
            top: -1000px;
            transition: 1s;
        }
    }

    &__overlay {
        @include mdMax {
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, .5);
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10;
            display: block;
        }
    }

    &__mb-top-btns {
        @include flexRow(center, space-between);
        padding: 16px;

        @include md {
            justify-content: flex-end;
            padding: 12px 16px 13px 0px;
        }

        &--borrar{
           @include flexRow(center, flex-start);
           gap: 7px;
           @include grayText(14px);
           cursor: pointer;

           @include md {
            justify-content: flex-end;
            font-size: 12px;
           }
        }

        &--close {
            font-size: 22px;
            cursor: pointer;
        }
    }

    &__mb-btn-container {
        width: 100%;
        padding: 16px;

        @include md {
            padding: 8px 0;
        }

        button {
           @include button(100%, 36px, 0); 
           font-size: 13px;
           font-weight: 500;
           cursor: pointer;
        }
    }
}