@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.catalogo-piezas {
    @include flexCol(center, flex-start);
    padding-top: 80px;
    padding-bottom: 20px;

    @include sm {
        padding: 80px 70px 20px;
    }

    @include md {
        padding: 80px 54px 0px;
    }

    @include lg {
        padding-top: 110px;
    }

    @include custom(1400px) {
        max-width: 1400px;
        margin: auto;
    }

    &__header {
        @include flexRow(center, space-between);
        flex-wrap: wrap;
        width: 100%;
        padding: 16px 16px 10px;
        color: $primary;
        height: 125px;
        position: fixed;
        z-index: 100;
        background-color: $white;
        top: 54px;

        &.from-lleida {
            height: 137px;
        }

        @include sm {
            padding: 16px 86px 10px;
        }

        @include md {
            height: 105px;
            padding: 40px 54px 7px;

            &.from-lleida {
                height: 120px;
                padding-top: 32px;
            }
        }

        @include lg {
            height: 120px;
            padding-top: 50px;

            &.from-lleida {
                height: 135px;
                padding-top: 47px;
            }
        }

        @include custom(1400px) {
            margin: auto;
            max-width: 1400px;
        }

        &--search {
            width: 100%;
            position: relative;

            @include md {
                width: 390px; 
                height: 39px;
            }
            
            @include lg {
                width: 420px;
                height: 43px;
            }

            &--input {
                width: 100%;
                @include basicInputCatalogoPiezas();
                outline-color: $primary;

                @include md {
                    font-size: 14px;
                    height: 100%;
                }
                
                @include lg {
                    font-size: 16px;
                    padding: 12px 16px;
                }
                
                &::placeholder {
                    color: $primary;
                }
            }

            &--delete, &--icon {
                font-size: 1.2rem;
                position: absolute;

                @include md {
                    font-size: 1.4rem;
                }
            }

            &--delete {
                top: 10%;
                right: 30px;
                display: none;

                &--show {
                    display: block;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
                
                @include md {
                    top: 16%;
                    right: 36px;
                }
            }
            
            &--icon {
                top: 22%;
                right: 6px;
                cursor: pointer;
                
                @include md {
                    top: 26%;
                    right: 10px;
                }
                &:hover {
                    transform: scale(1.1);
                }
            }
            
        }
        
        &--filtrosbtn {
            width: 48.2%;
            @include basicInputCatalogoPiezas();
            @include flexRow(center, space-between);

            span {
                @include flexCol(center, center);
                cursor: pointer;

                .filtros-img {
                    height: 18px;
                    width: 18px;
                }
            }

            @include md {
                display: none;
            }
        }
        
        &--order {
            width: 48.2%;
            border: 1px solid $primary;
            border-radius: 8px;
            padding: 7px;
            cursor: pointer;
            outline: none;
            color: $primary;

            @include md {
                width: 240px; 
                height: 39px;
                font-size: 14px;
            }

            @include lg {
                width: 311px;
                height: 43px;
                font-size: 16px;
                padding: 9px 16px;
            }

            svg {
                font-size: 1.2rem;
                cursor: pointer;

                @include md {
                    font-size: 1.4rem;
                }
            }
        }

        &--warehouse {
            @include flexRow(center, flex-start);
            padding: 6px 3px 5px 0px;
            width: 100% !important;
            gap: 6px;
            width: 400px;
            font-size: 13px;
        }
    }

    &__main {
        @include flexRow(flex-start, space-between);
        width: 100%;
        padding-top: 95px;
        position: relative;

        &.from-lleida {
            padding-top: 107px;
        }
        
        @include md {
            padding-top: 75px;

            &.from-lleida {
                padding-top: 90px;
            }
        }

        @include lg {
            padding-top: 60px;

            &.from-lleida {
                padding-top: 78px
            }
        }
        
        &--piezas {
            @include flexCol(center, flex-start);
            padding: 6px 16px 38px;
            gap: 10px;
            width: 100%;

            &--no-data {
                min-height: 70vh;
                width: 100%;
            }

            @include md {
                padding: 6px 0px 38px 15px;
            }

            @include lg {
                padding-left: 20px;
            }

        }
    }
}

