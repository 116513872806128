@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.incidencias-container-mob {
    width: 100%;

    &__header {
        padding: 4px 13px 8px;
        @include grayText(10px);
        display: grid;
        grid-template-columns: 1fr 2.2fr;
    }
}