@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.skeleton-factura-card {
  height: 256px;

  @include xl {
    height: 98px;
  }

  &__numero {
    @include skeletonBasics(13px, 75px, 3px);
    margin-bottom: 5px;

    @include xl {
      width: 80px;
    }

  }
  
  &__fecha {
    @include skeletonBasics(11px, 78px, 3px);

    @include xl {
      width: 70px;
    }

  }

  &__nombre {
    @include skeletonBasics(13px, 130px, 3px);
    margin-bottom: 5px;
  }

  &__modelo {
    @include skeletonBasics(11px, 100px, 3px);
  }

  &__presupuesto {
    @include skeletonBasics(12px, 78px, 3px);

    @include xl {
      justify-self: center;
    }
  }
  
  &__importe {
    @include skeletonBasics(12px, 60px, 3px);
    @include xl {
      justify-self: center;
    }
  }
  
  &__estado {
    @include skeletonBasics(12px, 70px, 3px);
    @include xl {
      justify-self: center;
    }
  }
  
  &__pago {
    @include skeletonBasics(12px, 50px, 3px);
    @include xl {
      justify-self: center;
    }
  }

  &__title {
    @include skeletonBasics(10px, 50px, 3px);
    margin-bottom: 7px;
  }
  
  &__descargar {
    border-color: $btnFotos;

    &--icon {
      border-color: $btnFotos;
    }

    &--text1 {
      @include skeletonBasics(8px, 55px, 3px);
      margin-top: 3px;
    }
    
    &--text2 {
      @include skeletonBasics(8px, 40px, 3px);
      margin-top: -3px;
    }
  }
}

.factura-card-loading-animation {
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}