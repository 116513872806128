@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.mis-direcciones {
    @include basicsEachSectionAreaPersonal();
    padding-bottom: 80px !important;

    @include md {
        max-width: 950px;
    }
    
    &__return {
        @include returnArrowAreaPersonal();
    }

    h2 {
        @include titleAreaPersonal();
        width: 180px;
        padding-bottom: 18px;

        @include md {
            width: 100%;
            padding-bottom: 26px;
        }
    }

    &__form {
        @include md {
            padding-bottom: 40px;
        }
    }

    &__title-facturacion {
        @include md {
            padding-top: 40px !important;
        }
    }

    &__container-skeleton {
        @include flexCol(flex-end, flex-end);
        gap: 15px;
        width: 100%;
    }

    &__direcciones {
        @include flexCol(flex-end, flex-end);
        gap: 15px;
        width: 100%;
        padding-bottom: 18px;
        
        @include md {
            padding-bottom: 40px;
        }
        
        & > div {
            width: 100%;
            @include flexCol(flex-end, flex-end);
            gap: 15px;
        }

        &--add {
           @include basicsAddNewButton();

           &:active {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            }
        }
    }

    &__separador {
        width: 100%;
        border-bottom: 1px solid $grayColor;
        margin: 32px 0 22px;

        @include md {
            display: none;
        }
    }

    &__info {
        @include flexRow(flex-start, flex-start);
        font-size: 11px;
        padding-left: 3px;

        &--padding {
            padding-top: 12px;
        }

        span {
            padding-right: 3px;
            @include primaryText(14px);
        }
    }

}