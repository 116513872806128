@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.pedidos-container-mob {
    width: 100%;
    padding-bottom: 50px;
    &__header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 0.5fr;
        padding: 10px 14px;
        font-size: 10px;
    }
}