@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.incidencias-container-desk {
    width: 100%;

    &__header {
        padding: 4px 13px 8px;
        @include grayText(10px);
        display: grid;
        grid-template-columns: 1fr 2.2fr;

        @include lg {
            grid-template-columns: 1fr 1.1fr 1fr 1fr 1fr 1fr;
            font-size: 11px;
        }
        
        @include xl {
            grid-template-columns: 1.1fr 1.4fr 1.1fr 1.1fr 1.1fr 1.1fr;
        }

        &--center {
            text-align: center;
        }
    }

    &__container-cards {
        @include lg {
          padding-bottom: 26px;
        }
      }
}