@import '../../../scss/styles/mixins';
@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';

.reenvio-email {
  padding-top: 130px;
  justify-content: flex-start;
  padding-bottom: 100px !important;
  height: 100% !important;
  min-height: 100vh !important;

  &__search {
    @include flexRow(center, space-between);
    padding-bottom: 80px;
    width: 288px;
    position: relative;

    @include lg {
      width: 390px;
      padding-bottom: 65px;
      padding-top: 10px;
    }

    input {
      border: none;
      background-color: transparent;
      border-bottom: 1px solid $light-gray;
      width: 100%;
      padding: 6px 3px 3px;
      font-size: 14px;

      @include lg {
        font-size: 18px;
      }
    }

    svg {
      position: absolute;
      right: 3px;
      color: $primary;
      font-size: 18px;
      cursor: pointer;

      @include lg {
        font-size: 22px;
      }
    }
    @include basicResetInput();
  }

  &__each-input {
    @include basicLabelAuth();
    width: 288px;

    @include lg {
        width: 390px;
    }

    &--input {
      @include basicInputAuth();
      @include flexRow(center, space-between);
      opacity: 0.7;
      background-color: $white;
    }
    
    &--editable-input {
      @include basicInputAuth();
      @include flexRow(center, space-between);
      background-color: rgba(255, 255, 255, 0.800);

      &--disabled-text {
        opacity: 0.8;
      }

      &--save-delete {
        @include flexRow(center, space-between);
        gap: 5px;

        @include lg {
          svg {
            font-size: 20px !important;
          }
        }
      }

      input {
        width: 80%;
        outline: none;
        border: none;

        @include lg {
          font-size: 16px;
        }
      }

      svg {
        cursor: pointer;
        font-size: 18px;
      }
    }

  }
  button:disabled {
      opacity: 0.7 !important;
  }
}
