@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.favoritos-card {
  display: grid;
  grid-template-columns: 1.2fr 7fr;
  width: 100%;
  min-height: 109px;
  background-color: $btnFilterBg;
  padding: 6px;
  border-radius: 6px;
  position: relative;
  align-items: center;

  &-hover-active {
    &:hover {
        cursor: pointer;
        transform: scale(1.01);
        transition: all 100ms ease-in-out;
    }
  }

  &.blocked {
    cursor: default !important;
    transform: scale(1) !important;

    .favoritos-card__cart {
      cursor: not-allowed !important;
      &:active {
        -webkit-transform: scale(1) !important;
        transform: scale(1) !important;
      }
    }
  }

  @include lg {
    grid-template-columns: 1.2fr 8fr 2fr 2fr 1fr;
  }
  
  @include xl {
    grid-template-columns: 1.2fr 6fr 3fr 1.8fr 1.8fr 0.7fr;
  }

  &__img {
    @include flexCol(center, center);
    height: 98px;
    width: 84px;
    border-radius: 10px;
    background-color: $white;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__text-container {
    padding: 0 10px;

    &--nombre {
      @include primaryText(15px);
      padding-bottom: 2px;
      @include capitalize();
      padding-top: 5px;

      @include lg {
        font-size: 17px;
      }
    }

    &--modelo {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 14px;
      padding-bottom: 10px;

      @include lg {
        font-size: 16px;
      }
    }

    &--casco {
      padding-top: 0;
      padding-bottom: 12px;
    }

    &--estado-stock {
      background-color: $primaryLight;
      width: 100px;
      @include flexCol(center, center);
      border-radius: 16px;
      font-size: 13px;
      font-weight: 600;
      padding: 4px 0;
      color: $primary;

      @include xl {
        justify-self: center;
        font-size: 14px;
        padding: 5px 0;
        width: 110px;
      }

      &.no-stock {
        background-color: rgb(231, 76, 76);
        color: $white;
        font-size: 11px;
        width: 100px;
      }
    }
  }

  &__precio {
    justify-self: center;

    @include lgMax {
      position: absolute;
      bottom: 7px;
      right: 60px;
    }

    &--consultar {
      font-size: 12px;
      right: 100px;
      max-width: 60px;
      text-align: center;
      bottom: 14px;

      @include xl {
        font-size: 14px;
      }
    }
  }

  &__cart {
    border: 3px solid $white;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    color: $primary;
    cursor: pointer;
    justify-self: center;
    position: relative;

    &:active {
      -webkit-transform: scale(0.96);
      transform: scale(0.96);
    }
    
    @include lgMax {
      position: absolute;
      bottom: 7px;
      right: 7px;
    }

    span {
      @include flexRow(center, center);
      background-color: $primaryLight;
      width: 100%;
      height: 100%;
      font-size: 1.5rem;
    }

    &--text {
      display: none !important;
    }
  }

  &__delete {
    cursor: pointer;
    justify-self: center;
    @include lgMax {
      position: absolute;
      top: 4px;
      right: 8px;
    }
  }

  &__no-data-btns {
    @include flexRow(flex-end, flex-end);
    gap: 6px;
    
    @include lgMax {
      position: absolute;
      bottom: 12px;
      right: 16px;
    }
    
    &--whats {
        @include primaryText(23px);

      }
      
    &--email {
        @include primaryText(24px);

    }
      
    &--whats, &--email {
        background-color: white;
        cursor: pointer;
        height: 34px;
        width: 34px;
        box-shadow: 0px 0px 10px $boxShadow;
        border-radius:6px;
        @include flexCol(center, center);

    }
}
}