@import '../../../scss/styles/mixins';
@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';

.listados {
  @include flexCol(center, flex-start);
  padding-top: 130px;

  @include sm {
    padding-top: 140px;
  }

  @include md {
    padding-top: 160px;
  }

  @include lg {
    padding-top: 190px;
  }

  h1 {
    @include primaryText(20px);
    text-align: center;
    padding-bottom: 60px;

    @include sm {
      font-size: 25px;
      padding-bottom: 65px;
    }
  }

  &__container {
    @include flexCol(center, center);
    gap: 20px;
    width: 80%;

    @include lg {
      flex-direction: row;
    }

    &--each-card {
      @include flexCol(center, center);
      gap: 10px;
      max-width: 320px;
      background-color: $btnFilterBg;
      padding: 22px 36px 20px;
      border-radius: 6px;
      text-align: center;

      h2 {
        font-size: 16px;

        @include sm {
          font-size: 18px;
        }
      }

      p {
        font-size: 13px;

        @include sm {
          font-size: 14px;
        }
      }

      &--icon {
        width: 55px;
        height: 55px;
        border-radius: 9px;
        padding: 0.56rem;
        background-color: $white;
        cursor: pointer;
        box-shadow: 2px 2px 10px $boxShadow;
        margin-top: 2px;

        @include smMax {
          height: 50px;
          width: 50px;
        }

        &--span {
          @include flexCol(center, center);
          background-color: $primaryLight;
          width: 36px;
          height: 36px;
          border-radius: 9px;
          font-size: 1.2rem;
          position: relative;

          @include smMax {
            height: 31px;
            width: 31px;
            font-size: 1.1rem;
          }
        }

        
      }
    }
  }
}