@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.pedidos-container-desk {
    width: 100%;
    &__header {
        display: grid;
        padding: 14px;
        grid-template-columns: 1fr 2.5fr;
        align-items: end;
        text-align: center;
        @include grayText(10px);
        
        @include xl {
            font-size: 11px;
            grid-template-columns: 1.1fr 2.4fr 1fr 1fr 1fr 2fr;
        }

        &--left-align {
            text-align: left;
        }

        &--transporte {
            max-width: 70px;
            text-align: center;
            justify-self: center;
        }

        &--acciones {
            min-width: 210px;
            @include xl {
                min-width: 220px;
            }
        }
    }


}