@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.search-by-date {
  width: 100%;

  &__button {
    padding-bottom: 30px;

    @include xl {
      padding-bottom: 0;
      height: 45px;
      @include flexRow(center, flex-end);
    }
    p {
      @include basicBusquedaAvanzada();
    }
  }

  &__dropdown {
    width: 100%;
    @include flexCol(flex-end, flex-end);

    &--borrar {
      margin-top: -9px;
      padding-bottom: 26px;

      @include xl {
        margin-top: 0;
      }
    }

    &--date {
      width: 100%;
      @include flexRow(center, space-between);
      gap: 13px;
      padding-bottom: 16px;

      @include custom(460px) {
        justify-content: flex-end;
      }

      @include xl {
        padding-bottom: 0;
      }

      p {
        font-size: 13px;
      }

      input {
        @include basicInput();
        @include basicInputDate();
        width: 97px;

        @include lg {
          width: 126px !important;
          max-width: none;
        }
      }
    }

    &--btn{
      @include flexRow(flex-end, flex-end);
      gap: 16px;
      padding-bottom: 30px;

      @include xl {
        padding-top: 16px;
      }

      button {
        @include button(97px, 32px, auto);
        cursor: pointer;
        position: relative;

        &:active {
          -webkit-transform: scale(0.98);
          transform: scale(0.98);
        }

        @include lg {
          width: 126px;
        }
      }
    }
  }
}