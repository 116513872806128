@import '../../../../scss/styles/mixins';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/theme/variables';

#input-paises {
  width: 100%;
  margin: 0 !important;
  background-color: $btnFilterBg;
  border-radius: 8px;
  height: 40px;
  padding: 0;

  fieldset { border: none !important; }
}

#input-paises > div {
  height: 100%;
  font-size: 13px !important;
}